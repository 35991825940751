import Typography from '@mui/material/Typography';
import { useQuery } from '@tanstack/react-query';
import React from 'react';

import Container from 'components/common/Container';
import WaveLine from 'components/waveLine/WaveLine';

import { ReactComponent as LabAnalysisIcon } from 'static/icons/LabAnalysis.svg';
import { ReactComponent as NearRealTimeMonitoringIcon } from 'static/icons/NearRealTimeMonitoring.svg';
import custom_palettes from 'theme/custom_palettes';
import cms from 'utils/cms';
import useWidth from 'utils/useWidth';

import AccordionHeader from './Accordion/Header';
import Accordion from './Accordion/Index';
// =============================================================================
const SYMBOL_DESCRIPTIONS = [
  {
    symbol: <NearRealTimeMonitoringIcon />,
    description: 'Near-real time monitoring',
    sampling_rate: 'Every 30 minutes'
  },
  {
    symbol: <LabAnalysisIcon />,
    description: 'Lab Analysis',
    sampling_rate: 'Monthly'
  }
];

// =============================================================================
export default function Parameters() {
  const width = useWidth();
  const {
    isLoading,
    isError,
    data: query
  } = useQuery({
    queryKey: ['parameter'],
    queryFn: () =>
      cms.get('/api/parameter?populate=deep').then((res) => res.data),
    refetchOnWindowFocus: 'always'
  });

  if (isLoading) return <div>Loading...</div>;
  if (isError) return <div>Something is wrong please reload</div>;

  const data = query?.data.attributes;

  const { parameterDescription, parameter } = data;

  return (
    <div>
      <Container style={{ marginBottom: 48, marginTop: 48 }}>
        <Typography variant='h2' color='blue.900'>
          Parameters
        </Typography>
        <div style={{ height: 8 }} />
        <Typography
          variant='body_large'
          color='blue.900'
          style={{ whiteSpace: 'pre-wrap' }}
        >
          {parameterDescription}
        </Typography>
      </Container>

      <WaveLine style={{ fill: custom_palettes.yellow[200] }} />

      <div style={{ backgroundColor: custom_palettes.yellow[200] }}>
        <div style={{ height: 64 }} />
        <Container>
          {generateSymbolDescriptions()}
          <div style={{ height: 48 }} />
          {width === 'mobile' ? null : <AccordionHeader />}
          {generateParameterBars()}
        </Container>
        <div style={{ height: 160 }} />
      </div>
    </div>
  );

  function generateParameterBars() {
    return parameter.map((par: any, index: number) => (
      <React.Fragment key={index}>
        <Accordion parameter={par} />
        <div style={{ height: 24 }} />
      </React.Fragment>
    ));
  }

  function generateSymbolDescriptions() {
    return (
      <div style={{ display: 'flex' }}>
        {SYMBOL_DESCRIPTIONS.map((desc: any, index: number) => (
          <React.Fragment key={index}>
            <div style={{ display: 'flex', marginRight: 40 }}>
              <div>{desc.symbol}</div>
              <div style={{ width: 8 }} />
              <div>
                <Typography variant='overline' color='blue.900'>
                  {desc.description}
                </Typography>
                <br />
                <Typography variant='assistive_text' color='gray.800'>
                  {desc.sampling_rate}
                </Typography>
              </div>
            </div>
          </React.Fragment>
        ))}
      </div>
    );
  }
}
