import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useQuery } from '@tanstack/react-query';
import { isEmpty } from 'lodash';
import React from 'react';
import { If, Then } from 'react-if';
import { Link as RouterLink } from 'react-router-dom';
import YouTube from 'react-youtube';

import Container from 'components/common/Container';
import PageTitle from 'components/text/PageTitle';
import WaveLine from 'components/waveLine/WaveLine';
import Station from 'pages/OurBuoy/Station';

import custom_palettes from 'theme/custom_palettes';
import cms from 'utils/cms';
import useViewportRatio from 'utils/useViewportRatio';
import useWidth from 'utils/useWidth';

import Capability from './Capability';
import Intro from './Intro';

// =============================================================================
export default function OurBuoysPage() {
  const {
    isLoading,
    isError,
    data: query
  } = useQuery({
    queryKey: ['our-buoy'],
    queryFn: () =>
      cms.get('/api/our-buoy?populate=deep').then((res) => res.data)
  });
  const standard = parseInt(import.meta.env.VITE_STANDARD_VIEWPORT as string);
  const width = useWidth();
  const ratio = useViewportRatio(standard);

  if (isLoading) return <div>Loading...</div>;
  if (isError) return <div>Something is wrong please reload</div>;

  const data = query?.data.attributes;

  const {
    buoy,
    capability,
    capabilityList,
    youtubeVideoId,
    buoyDesignDescription,
    station,
    stationList
  } = data;
  console.log(buoyDesignDescription);

  return (
    <div>
      <If condition={!!station && !!stationList}>
        <Then>
          <Station {...station} stationList={stationList} />
        </Then>
      </If>

      <Container>
        <PageTitle text='Our Buoys' />
      </Container>

      <Intro {...buoy} buoyDesignDescription={buoyDesignDescription} />

      <Capability {...capability} capabilityList={capabilityList} />

      <div
        style={{
          position: 'relative',
          backgroundColor: custom_palettes.green[50],
          marginLeft: 0,
          marginRight: 0,
          width: '100%'
        }}
      >
        <If condition={!isEmpty(youtubeVideoId)}>
          <Then>
            <Container style={{ paddingTop: 96 }}>
              <div
                style={{
                  // marginTop: 96,
                  display: 'flex',
                  justifyContent: 'center',
                  ...(width === 'mobile' && { marginBottom: 96 })
                }}
              >
                <YouTube
                  videoId={youtubeVideoId}
                  opts={{
                    width: width === 'mobile' ? '100%' : 750 * ratio,
                    height: width === 'mobile' ? 300 : 500 * ratio
                  }}
                />
              </div>
              <div
                style={{
                  height: 80
                }}
              />
            </Container>
          </Then>
        </If>
      </div>

      <div
        style={{
          backgroundColor: custom_palettes.yellow[200],
          height: 450
        }}
      >
        <div style={{ backgroundColor: custom_palettes.green[50] }}>
          <WaveLine style={{ fill: custom_palettes.yellow[200] }} />
        </div>

        <div
          style={{
            textAlign: 'center' as const,
            width: '100%',
            height: '60%',
            display: 'flex',
            flexDirection: 'column' as const,
            justifyContent: 'center' as const,
            alignItems: 'center' as const
          }}
        >
          <Typography variant='h3'>
            Interested in partnering with us?
          </Typography>
          <div style={{ height: 24 }} />
          <Button
            variant='primary'
            color='dark'
            component={RouterLink}
            to='/contact-us'
          >
            Contact Us
          </Button>
        </div>
      </div>
    </div>
  );
}
