import ArrowForwardIosNewRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { useQuery } from '@tanstack/react-query';
import { isEmpty } from 'lodash';
import React, { useEffect } from 'react';
import { Else, If, Then } from 'react-if';
import ReactMarkdown from 'react-markdown';
import { Link as RouterLink } from 'react-router-dom';

import Container from 'components/common/Container';
import PageTitle from 'components/text/PageTitle';
import WaveLine from 'components/waveLine/WaveLine';

import custom_palettes from 'theme/custom_palettes';
import custom_typographys from 'theme/custom_typographys';

import cms from 'utils/cms';
import useViewportRatio from 'utils/useViewportRatio';
import useWidth from 'utils/useWidth';

// =============================================================================
export default function Publications() {
  const {
    isLoading,
    isError,
    data: query
  } = useQuery({
    queryKey: ['publication'],
    queryFn: () =>
      cms.get('/api/publication?populate=deep').then((res) => res.data)
  });
  const standard = parseInt(import.meta.env.VITE_STANDARD_VIEWPORT as string);
  const width = useWidth();
  const ratio = useViewportRatio(standard);

  if (isLoading) return <div>Loading...</div>;
  if (isError) return <div>Something is wrong please reload</div>;

  const data = query?.data.attributes;

  const { title, list, description } = data;

  return (
    <div>
      <Container style={{ marginBottom: 48, marginTop: 48 }}>
        <Typography variant='h2' color='blue.900'>
          {title}
        </Typography>
        <div style={{ height: 8 }} />
        <Typography
          variant='body_large'
          color='blue.900'
          style={{ whiteSpace: 'pre-wrap' }}
        >
          {description}
        </Typography>
        {list.map((item: any) => (
          <div key={item.id}>
            <div
              style={{
                marginTop: 30,
                marginBottom: 20
              }}
            >
              <Typography variant='subtitle' color='blue.900'>
                {item.year}
              </Typography>
            </div>
            {item.publications.map((el: any, index: any) => (
              <div
                style={{
                  marginBottom: 10,
                  display: 'flex',
                  alignItems: 'center'
                }}
                key={`${item.year}-${index}`}
              >
                <ArrowForwardIosNewRoundedIcon
                  sx={{ color: 'red', width: 15, height: 15 }}
                />
                <div style={{ width: 8 }} />
                <div>
                  <Link
                    href={el.link}
                    target='_blank'
                    rel='noopener noreferrer'
                    underline='none'
                    color='blue.800'
                    style={{
                      ...custom_typographys.button,
                      wordBreak: 'break-all'
                    }}
                  >
                    {el.title}
                  </Link>{' '}
                  - {el.description}
                </div>
              </div>
            ))}
          </div>
        ))}
      </Container>

      <div style={{ height: 160 }} />
    </div>
  );
}
