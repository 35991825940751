// import { Box } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import Modal from '@mui/material/Modal';
import { ThemeProvider } from '@mui/material/styles';
import _ from 'lodash';
import moment from 'moment-timezone';
import { useEffect, useState } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter, Route, Routes, useLocation } from 'react-router-dom';
import { store } from 'redux/store';
import theme from 'theme/theme';
import Cookies from 'universal-cookie';

import { ConstantsProvider } from 'components/providers/ConstantsProvider';
import { QueryClientProvider } from 'components/providers/QueryClientProvider';

// import Announcement from 'components/appbar/web/Announcement';
import WebAppbar from 'components/appbar/web/WebAppbar';
import WebInfoBar from 'components/appbar/web/WebInfoBar';
import WebFooter from 'components/footer/web/WebFooter';

import MobileAppBar from 'components/appbar/mobile/Index';
import MobileFooter from 'components/footer/mobile/Footer';

import AboutUs from 'pages/AboutUs/Index';
import FullAvatar from 'pages/AboutUs/Team/FullAvatar';
import AccessData from 'pages/AccessData/Index';
import Collaborate from 'pages/Collaborate/Index';
import ContactUs from 'pages/ContactUs/Index';
import ContributeData from 'pages/ContributeData/Index';
import DataUsePolicy from 'pages/DataUsePolicy/Index';
import FAQ from 'pages/FAQ/Index';
import ArticleView from 'pages/Highlights/ArticleView';
import Highlights from 'pages/Highlights/Index';
import Home from 'pages/Home/Index';
import OurBuoy from 'pages/OurBuoy/Index';
import OurWaters from 'pages/OurWaters/Index';
import Parameters from 'pages/Parameters/Index';
import Publications from 'pages/Publications/Index';
import ResourceDirectory from 'pages/ResourceDirectory/Index';

import Login from 'pages/Auth/Login';
import ResetPassword from 'pages/Auth/ResetPassword';
import Signup from 'pages/Auth/Signup';
import UserProfilePage from 'pages/Auth/UserProfilePage';

import BuoyData from 'pages/BuoyData/Index';
import MobileBuoyData from 'pages/BuoyData/Mobile';
import MMAEvent from 'pages/MMAEvent/Index';

import { useAppDispatch } from 'redux/hooks';
import { setParameters } from 'redux/slice/parametersControllerSlice';
import 'theme/markdown_style.css';
import { fetchUserParams, getAppVersion } from 'utils';
import api from 'utils/api';
import useWidth from 'utils/useWidth';
import NotFound from 'pages/NotFound/Index';

// Set global default timezone for moment object
moment.tz.setDefault('Asia/Singapore');

function GlobalModal() {
  return (
    <Modal open={true}>
      <div
        style={{
          width: '100%',
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <CircularProgress color='primary' size={40} />
      </div>
    </Modal>
  );
}

// =============================================================================
export default function App() {
  const [showModal, setShowModal] = useState(false);
  useEffect(() => {
    document.title = 'Ombak (v2.0)';
  }, []);

  api.interceptors.request.use(
    (config: any) => {
      if (!config.dontShowGlobalLoader) {
        setShowModal(true);
      }
      return config;
    },
    (error: any) => {
      setShowModal(false);
      return Promise.reject(error);
    }
  );

  api.interceptors.response.use(
    (response: any) => {
      setShowModal(false);
      return response;
    },
    (error: any) => {
      setShowModal(false);
      return Promise.reject(error);
    }
  );

  return (
    <QueryClientProvider>
      <Provider store={store}>
        {showModal && <GlobalModal />}
        <BrowserRouter>
          <ThemeProvider theme={theme}>
            <View />
          </ThemeProvider>
        </BrowserRouter>
      </Provider>
    </QueryClientProvider>
  );
}

// =============================================================================
const admin_view_style = {
  width: '100%',
  height: '100vh'
};

const View = () => {
  const width = useWidth();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const path = useLocation().pathname;
  const cookies = new Cookies();
  const isLoggedIn = !!cookies.get('tokens');

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  useEffect(() => {
    const execute = async () => {
      if (isLoggedIn) {
        const params: any = await fetchUserParams();
        dispatch(setParameters(params));
      }
    };

    execute();
  }, []);

  return (
    <ConstantsProvider>
      <div style={generateStyle()}>
        {width === 'mobile' ? <MobileAppBar /> : setAppbar(path)}
        <Routes>
          {width === 'mobile' ? (
            <Route element={<MobileBuoyData />} path='/data' />
          ) : (
            <Route element={<BuoyData />} path='/data' />
          )}
          <Route element={<Home />} path='/' />
          <Route element={<Parameters />} path='/more/parameters' />
          <Route element={<Publications />} path='/more/publications' />
          <Route
            element={<ResourceDirectory />}
            path='/more/resource-directory'
          />
          <Route element={<FAQ />} path='/faq' />
          <Route element={<MMAEvent />} path='/events/mmaworkshop' />
          <Route element={<ContactUs />} path='/contact-us' />
          <Route element={<Highlights tagSearch={false} />} path='/highlights' />
          <Route element={<ArticleView />} path='/highlights/:id' />
          <Route
            element={<Highlights tagSearch={true} />}
            path='/highlights/tag/:tag'
          />
          <Route element={<ArticleView featured />} path='/featured/:id' />
          <Route element={<AboutUs />} path='/about-us' />
          <Route element={<FullAvatar />} path='/about-us/:id' />
          <Route element={<OurBuoy />} path='/our-buoys' />
          <Route element={<OurWaters />} path='/our-waters' />
          <Route element={<AccessData />} path='/how-to/access-data' />
          <Route element={<ContributeData />} path='/how-to/contribute-data' />
          <Route element={<Collaborate />} path='/how-to/collaborate' />

          <Route element={<Signup />} path='/signup' />
          <Route element={<Login />} path='/login' />
          <Route element={<UserProfilePage />} path='/user-profile' />
          <Route element={<ResetPassword />} path='/resetPassword' />
          <Route element={<DataUsePolicy />} path='/data-use-policy' />
          <Route path='*' element={<NotFound />} />
        </Routes>
        {width === 'mobile' ? <MobileFooter /> : setFooter(path)}
      </div>
    </ConstantsProvider>
  );

  function generateStyle() {
    return location.pathname === '/data' ? admin_view_style : {};
  }

  function setAppbar(path: string) {
    switch (path) {
      case '/':
        return null;
      case '/user-profile':
      case '/data':
        return (
          <>
            {/* <Announcement variant='admin' /> */}
            <WebInfoBar variant='admin' />
            <WebAppbar variant='admin' />
          </>
        );
      default:
        return (
          <>
            {/* <Announcement /> */}
            <WebInfoBar />
            <WebAppbar />
          </>
        );
    }
  }

  function setFooter(path: string) {
    switch (path) {
      case '/data':
        return null;
      default:
        return <WebFooter />;
    }
  }
};
