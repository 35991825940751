import AccordionSummary from '@mui/material/AccordionSummary';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import React from 'react';

import { ReactComponent as ClimateChangeV2Icon } from 'static/icons/ClimateChangeV2.svg';
import { ReactComponent as EnvironmentImpactAssessmentsV2Icon } from 'static/icons/EnvironmentImpactAssessmentsV2.svg';
import { ReactComponent as LabAnalysisIcon } from 'static/icons/LabAnalysis.svg';
import { ReactComponent as MarineEcologyV2Icon } from 'static/icons/MarineEcologyV2.svg';
import { ReactComponent as MarineSensingTechnologies } from 'static/icons/MarineSensingTechnologies.svg';
import { ReactComponent as NearRealTimeMonitoringIcon } from 'static/icons/NearRealTimeMonitoring.svg';
import { ReactComponent as OceanBiogeochemistryV2Icon } from 'static/icons/OceanBiogeochemistryV2.svg';
import { ReactComponent as PhysicalOceanographyIcon } from 'static/icons/PhysicalOceanography.svg';

import custom_palettes from 'theme/custom_palettes';
import useWidth from 'utils/useWidth';

import { parameterLocations } from './utils';

// =============================================================================
const ICON_OPACITY = 0.2;

const icon_size = {
  height: 32,
  width: 32
};

const locationStyle = {
  ...icon_size,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '0 10px',
  color: '#00B087',
  border: '2px solid #00B087',
  borderRadius: '15px'
};

// =============================================================================
interface SummaryProps {
  title: string;
  subtitle: string;
  acquiredBy: string;
  labAnalysis: boolean;
  realTime: boolean;
  locations: any;
}

export default function Summary(props: SummaryProps) {
  const width = useWidth();
  const { title, subtitle, acquiredBy, labAnalysis, realTime, locations } =
    props;

  return (
    <AccordionSummary
      sx={{
        backgroundColor: custom_palettes.white[50],
        borderRadius: 16,
        padding: ''
      }}
    >
      <Grid
        container
        spacing={4}
        style={{
          display: 'flex',
          alignItems: 'center'
        }}
      >
        <Grid item xs={width === 'mobile' ? 9 : 3}>
          <Typography variant='body_regular_strong' color='blue.900'>
            {title}
          </Typography>
          <br />
          <Typography variant='assistive_text' color='gray.700'>
            {subtitle}
          </Typography>
        </Grid>

        {width === 'mobile' ? null : (
          <Grid item xs={3}>
            <Typography
              variant='body_regular'
              color='blue.900'
              style={{ whiteSpace: 'pre-line' }}
            >
              {acquiredBy}
            </Typography>
          </Grid>
        )}

        <Grid item xs={width === 'mobile' ? 3 : 2} style={{ display: 'flex' }}>
          <NearRealTimeMonitoringIcon
            style={{ opacity: realTime ? 1 : ICON_OPACITY }}
          />
          <div style={{ width: 16 }} />
          <LabAnalysisIcon
            style={{ opacity: labAnalysis ? 1 : ICON_OPACITY }}
          />
        </Grid>

        {width === 'mobile' ? null : (
          <Grid
            item
            xs={4}
            style={{ display: 'flex', justifyContent: 'space-around' }}
          >
            {Object.keys(locations).map((location) => (
              <div
                key={location}
                style={{
                  opacity: locations[location] ? 1 : ICON_OPACITY,
                  ...locationStyle
                }}
              >
                {parameterLocations[location] ?? location}
              </div>
            ))}
          </Grid>
        )}
      </Grid>
    </AccordionSummary>
  );
}
