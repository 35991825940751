import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { useAppSelector } from 'redux/hooks';
import { exportRawData } from 'components/common/DataDownloader';
import moment from 'moment';
import api from 'utils/api';

// =============================================================================
// 40: appbar, 94.5: appbar, 80: options, 400: chart
const VIEW_HEIGHT = window.innerHeight - 40 - 94.5 - 80 - 400;

// =============================================================================
const TITLES = ['CSV'];

const HEADERS = [
  'Date',
  'Locations',
  'Seasons',
  'Tidal cycles',
  'WQ All depths DCI (M)'
];

const FAKE_DATA_POINTS = {
  date: 'Mon Aug 30th 2010',
  locations: 'SJI',
  seasons: 5,
  tidalCycles: 0,
  depths: 0
};

// =============================================================================
function generateFakeData() {
  let data = [];
  for (let i = 0; i < 100; i++) {
    data.push(FAKE_DATA_POINTS);
  }
  return data;
}

export default function RawDataView() {
  const graphController = useAppSelector((state) => state.graphController);
  const cardSet = useAppSelector((state) => state.datasetCardController.set);

  const getTitle = () => {
    const result: string = cardSet.reduce(
      (title: any, card: any) => title + card.parameterDesc + ' and ',
      ''
    ) as string;

    return result.length > 0 ? result.substring(0, result.length - 5) : 'N/A';
  };

  const handleDownload = async () => {
    const start = graphController.startTime;
    const end = graphController.endTime;
    alert(
      'Note: The downloaded data for Sensor Live parameters will be for first month only from the chosen time range'
    );
    cardSet.map(async (card: any) => {
      try {
        const res = await api.post('/api/rawData/download', {
          parameterId: card.id.toString(),
          from: start,
          to: end,
          depth: card.depth.value
        });
        exportRawData(
          `${res.data.fileName}_${moment().valueOf()}_${start}_${end}`,
          res.data.data,
          card.isCruiseParam,
          card.unit
        );
        alert('Successfully downloaded');
      } catch (err: any) {
        alert(err.response.data.message);
      }
    });
  };

  return (
    <div style={{ margin: '0 48px', minHeight: VIEW_HEIGHT }}>
      <Divider />
      <div style={{ height: 40 }} />
      <div style={{ display: 'flex' }}>
        <div style={{ flex: 1 }} />
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Typography variant='overline' color='blue.900'>
            Download
          </Typography>
          <Button
            variant='secondary'
            color='dark'
            style={{ marginLeft: 8 }}
            onClick={handleDownload}
          >
            {TITLES[0]}
          </Button>
        </div>
      </div>
      <div style={{ height: 40 }} />
      {/* <Grid 
        container 
        columns={10}
      >
        {generateDataHeaders(HEADERS)}
      </Grid>
      <div style={{
          overflow: "scroll",
          height: "50%"
        }}
      >
        <Grid 
          container 
          columns={10}
        >
          {generateDataView(generateFakeData())}
        </Grid>
      </div> */}
    </div>
  );

  function generateDataHeaders(headers: string[]) {
    return headers.map((header: string, index: number) => (
      <Grid item xs={2} key={index} style={{ marginBottom: 16 }}>
        <Typography variant='caption' color='blue.900'>
          {header}
        </Typography>
      </Grid>
    ));
  }

  function generateDataView(dataPoints: object[]) {
    return dataPoints.map((data: any) =>
      Object.values(data).map((value: any, index: number) => (
        <Grid item xs={2} key={index}>
          <Typography variant='overline' color='blue.900'>
            {value}
          </Typography>
        </Grid>
      ))
    );
  }
}
