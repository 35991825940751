import { type PropsWithChildren, useState } from "react";

import { QueryClient, QueryClientProvider as TQCProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: true,
      staleTime: 10 * 60 * 1000, // 10 minutes
      gcTime: 10 * 60 * 1000 // 10 minutes
    }
  }
})

export const QueryClientProvider = ({ children }: PropsWithChildren<{}>) => {
  // const [queryClient] = useState(new QueryClient({
  //   defaultOptions: {
  //     queries: {
  //       retry: true,
  //       staleTime: 10 * 60 * 1000, // 10 minutes
  //       gcTime: 10 * 60 * 1000 // 10 minutes
  //     }
  //   }
  // }));
  return (
    <TQCProvider client={queryClient}>
      {children}
      <ReactQueryDevtools initialIsOpen={false}/>
    </TQCProvider>
  )
}