import Typography from '@mui/material/Typography';
import { useQuery } from '@tanstack/react-query';
import React from 'react';
import ReactMarkdown from 'react-markdown';

import Container from 'components/common/Container';
import WaveLine from 'components/waveLine/WaveLine';

import custom_palettes from 'theme/custom_palettes';
import cms from 'utils/cms';
import useWidth from 'utils/useWidth';


// =============================================================================
export default function DataUsePolicy() {
  const width = useWidth();
  const {
    isLoading,
    isError,
    data: query
  } = useQuery({
    queryKey: ['data-use-policy'],
    queryFn: () =>
      cms.get('/api/data-use-policy?populate=deep').then((res) => res.data)
  });

  if (isLoading) return <div>Loading...</div>;
  if (isError) return <div>Something is wrong please reload</div>;

  const data = query?.data.attributes;

  const { description } = data;

  return (
    <div>
      <Container style={{ marginBottom: width === 'mobile' ? 0 : 24, marginTop: width === 'mobile' ? 24 : 48 }}>
        <Typography variant={width === 'mobile' ? 'body_large_strong' : 'h2'} color='blue.900'>
          Data Use Policy
        </Typography>
      </Container>

      <WaveLine style={{ fill: custom_palettes.yellow[50] }} />

      <div style={{ background: custom_palettes.yellow[50] }}>
        <div style={{ height: width === 'mobile' ? 24 : 80 }} />
        <Container>
          <ReactMarkdown className='markdown'>{description}</ReactMarkdown>
        </Container>
        <div style={{ height: 104 }} />
      </div>
    </div>
  );
}
