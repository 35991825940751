export const highlight = {
  title: 'Highlights'
};

export const CONSTANTS: any = {
  LOCATION_ACRONYM_TO_FULL_FORM_MAP: {},
  LOCATION_FULL_FORM_TO_ACRONYM_MAP: {},
  INTERVALS: {},
  DEPTH_ACRONYM_TO_FULL_FORM_MAP: {},
  DEPTH_FULL_FORM_TO_ACRONYM_MAP: {}
};
