import { useQuery } from '@tanstack/react-query';
import * as d3Time from 'd3-time';
import * as d3TimeFormat from 'd3-time-format';
import momentTZ from 'moment-timezone';
import { useEffect } from 'react';

import { useAppDispatch } from 'redux/hooks';
import { updatePreviewChartData } from 'redux/slice/previewDataControllerSlice';
import api, { oas3Api, ROUTES } from 'utils/api';

const { apiPath, getTypedApiData } = ROUTES.POST_LATEST_GRAPH_DATA;

interface SensorDataRequest {
  sensorClass: string;
  quantityName: string;
  nodeId: number;
}

type Dispatch = ReturnType<typeof useAppDispatch>;

const getWindDirection = (deg: number) => {
  if (deg >= 22.5 && deg < 67.5) return 'NE';
  if (deg >= 67.5 && deg < 112.5) return 'E';
  if (deg >= 112.5 && deg < 157.5) return 'SE';
  if (deg >= 157.5 && deg < 202.5) return 'S';
  if (deg >= 202.5 && deg < 247.5) return 'SW';
  if (deg >= 247.5 && deg < 292.5) return 'W';
  if (deg >= 292.5 && deg < 337.5) return 'NW';
  return 'N';
};

const formatMillisecond = d3TimeFormat.timeFormat('.%L'),
  formatSecond = d3TimeFormat.timeFormat(':%S'),
  formatMinute = d3TimeFormat.timeFormat('%H:%M'),
  formatHour = d3TimeFormat.timeFormat('%H:%M, %d %b'),
  formatDay = d3TimeFormat.timeFormat('%d %b'),
  formatWeek = d3TimeFormat.timeFormat('%d %b'),
  formatMonth = d3TimeFormat.timeFormat('%B'),
  formatYear = d3TimeFormat.timeFormat('%Y');

function multiFormat(date: Date) {
  return (
    d3Time.timeSecond(date) < date
      ? formatMillisecond
      : d3Time.timeMinute(date) < date
        ? formatSecond
        : d3Time.timeHour(date) < date
          ? formatMinute
          : d3Time.timeDay(date) < date
            ? formatHour
            : d3Time.timeMonth(date) < date
              ? d3Time.timeWeek(date) < date
                ? formatDay
                : formatWeek
              : d3Time.timeYear(date) < date
                ? formatMonth
                : formatYear
  )(date);
}

const mapSuccessfulApiResponse = (
  response: ReturnType<typeof getTypedApiData>
) => {
  const ACCURACY = 2;
  return response.map(({ qcValue, timestamp }) => {
    const rawValue = Number(qcValue).toFixed(ACCURACY);
    const timeStamp = Number(timestamp);
    return {
      y: rawValue,
      x: momentTZ.utc(Number(timeStamp)).tz('Asia/Singapore')
    };
  });
};

const useLandingPageData = ({
  location,
  parameter,
  dispatch
}: {
  parameter: string;
  location: string;
  dispatch: Dispatch;
}) => {
  const { data, status } = useQuery({
    queryKey: ['home', 'graph', 'graph-data', location, parameter],
    queryFn: async () => {
      return await api({
        method: 'post',
        url: `${import.meta.env.VITE_API_URL}${apiPath}`,
        data: {
          location,
          quantity: parameter
        },
        dontShowGlobalLoader: true
      } as any).then((res) => res.data);
    },
    refetchInterval: 5 * 60 * 1000
  });

  useEffect(() => {
    if (status === 'success' && data) {
      dispatch(
        updatePreviewChartData({
          location,
          quantity: parameter,
          data: mapSuccessfulApiResponse(data)
        })
      );
    }
  }, [data, status]);
};

export { getWindDirection, multiFormat, useLandingPageData };
