import Typography from '@mui/material/Typography';
import React from 'react';
import { useAppDispatch, useAppSelector } from 'redux/hooks';

import Container from 'components/common/Container';
import DetailCard from 'components/detailCard/DetailCard';
import InteractiveMap from 'components/map/InteractiveMap';
import HeadingBodyOverline from 'components/text/HeadingBodyOverline';
import WaveLine from 'components/waveLine/WaveLine';
import { transformToPoint } from 'redux/slice/mapControllerSlice';

import custom_palettes from 'theme/custom_palettes';
import useViewportRatio from 'utils/useViewportRatio';

import MobileDetailCard from './DetailCard';
import ResetMapButton from './ResetMapButton';
import { type BuoyProps, getCardData, useUpdateBuoyCard } from './utils';

// =============================================================================
const SIDE_MARGIN = 150;
const BUOY_TITLE_WIDTH = 716;
const BUOY_TEXT_WIDTH = 634;

const cardTransforms = [
  'scale(2.5) translate(20px, -120px)',
  'scale(2.5) translate(160px, -240px)',
  'scale(2.5) translate(-260px, 130px)'
];

const headersActive = [false, false, false];

// =============================================================================
export default function Buoy(props: BuoyProps) {
  const width = props.width;

  const dispatch = useAppDispatch();
  const transform = useAppSelector((state) => state.mapController.transform);
  const activePoint = useAppSelector(
    (state) => state.mapController.activePoint
  );

  const standardRatio = parseInt(
    import.meta.env.VITE_STANDARD_VIEWPORT as string
  );
  const ratio = useViewportRatio(standardRatio);

  const [cardData, setCardData] = React.useState(getCardData(props));
  const [overline, setOverline] = React.useState('');

  useUpdateBuoyCard({ activePoint, setCardData, setOverline });

  return (
    <div
      style={{
        backgroundColor: custom_palettes.green[50],
        position: 'relative' as const,
        overflow: 'hidden',
        minHeight: 900
      }}
    >
      <div style={{ height: 80 }} />
      <div style={{ position: 'relative' as const, zIndex: 10 }}>
        <Container>
          <HeadingBodyOverline
            title={props.title}
            titleVariant={width === 'mobile' ? 'h3' : 'h2'}
            titleWidth={width === 'mobile' ? undefined : BUOY_TITLE_WIDTH}
            text={props.description}
            textVariant={width === 'mobile' ? 'regular' : 'body_large'}
            textWidth={width === 'mobile' ? undefined : BUOY_TEXT_WIDTH * ratio}
            overlineText={overline}
            overlineTextVariant='overline'
          />
          <div style={{ height: 20 }} />
          {transform === '' ? null : (
            <ResetMapButton onClick={() => dispatch(transformToPoint(''))} />
          )}

          {width === 'mobile' ? <div style={{ height: 16 }} /> : null}
        </Container>
      </div>

      <Container>
        {/* <div style={{ backgroundColor: 'red', minHeight: 250 }} /> */}
        <InteractiveMap
          mobile={width === 'mobile'}
          transition='transform 1s'
          style={{
            width: width === 'mobile' ? undefined : '50%'
          }}
        />
        <Typography
          variant='assistive_text'
          color='blue.900'
          position={'relative'}
          zIndex={1}
        >
          This map is an approximate view of Singapore for illustration purposes
          only.
        </Typography>

        {Object.keys(cardData).length > 0 ? (
          width === 'mobile' ? (
            <React.Fragment>
              <Container>
                <Typography variant='assistive_text' color='green.500'>
                  This map is an approximate view of Singapore for illustration
                  purposes only.
                </Typography>
              </Container>
              <MobileDetailCard
                cardData={cardData}
                setOverline={setOverline}
                disabled={headersActive}
              />
            </React.Fragment>
          ) : (
            <div
              style={{
                right: SIDE_MARGIN * ratio,
                position: 'absolute' as const,
                top: 80,
                minWidth: 300,
                width: 357,
                maxWidth: 400,
                zIndex: 99
              }}
            >
              <DetailCard
                cardData={cardData}
                transforms={cardTransforms}
                disabled={headersActive}
                style={{
                  borderRadius: 8,
                  backgroundColor: 'rgba(255, 253, 251, 0.9)',
                  boxShadow: '0px 0px 15px rgba(0, 0, 0, 0.25)'
                }}
              />
              <div style={{ height: 16 }} />
            </div>
          )
        ) : (
          <div />
        )}
      </Container>

      <div style={{ height: 96 }} />
      <WaveLine
        style={{
          fill: custom_palettes.white.main,
          bottom: 0,
          width: '100%',
          position: 'absolute' as const
        }}
      />
    </div>
  );
}
