import { useContext, useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';

import api, { ROUTES } from 'utils/api';
import { z } from 'zod';

import { CONSTANTS as _CONSTANTS } from '../../constants';
import { ConstantsContext } from 'components/providers/ConstantsProvider';
import { queryClient } from 'components/providers/QueryClientProvider';

const sharedConstants = z.object({
  LOCATION_ACRONYM_TO_FULL_FORM_MAP: z.record(z.string(), z.string()),
  LOCATION_FULL_FORM_TO_ACRONYM_MAP: z.record(z.string(), z.string()),
  INTERVALS: z.record(z.string(), z.string()),
  DEPTH_ACRONYM_TO_FULL_FORM_MAP: z.record(z.string(), z.string()),
  DEPTH_FULL_FORM_TO_ACRONYM_MAP: z.record(z.string(), z.string())
});

export type ConstantsType = z.infer<typeof sharedConstants>;

export const ConstantsQueryKey = ['constants'];

export const useConstantsProviderHook = () => {
  const [CONSTANTS, setCONSTANTS] = useState(_CONSTANTS);
  const { data, status, error } = useQuery<ConstantsType>({
    queryKey: ConstantsQueryKey,
    queryFn: () =>
      api({
        method: 'get',
        url: `${import.meta.env.VITE_API_URL}${ROUTES.GET_CONSTANTS.apiPath}`,
        dontShowGlobalLoader: true
      } as any)
        .then((res) => res.data)
        .then((data) => sharedConstants.parse(data)),
    refetchOnWindowFocus: 'always'
    // gcTime: 5 * 60 * 1000, // Will be GC-ed after this
    // staleTime: 5 * 60 * 1000, // Will be considered stale after this
    // refetchInterval: 1000 // Will auto refetch in bg at this interval
  });
  useEffect(() => {
    if (data && status === 'success') {
      console.log('fetchin constant hoho', data);
      setCONSTANTS(data);
    } else if (error !== null && status === 'error') {
      console.log('An error occurred fetching constants', error);
    }
  }, [data, status]);

  return {
    CONSTANTS
  };
};

export const useConstants = () => {
  const CONSTANTS = useContext(ConstantsContext);
  return { CONSTANTS };
};

// Utility Function. We only do this as a last resort when we are calling isolated functions.
// Else, we should make use of the provided `useConstants` hook to retrieve data via the Context API.
export const getConstantsFromCache = () => {
  return queryClient.getQueryData<ConstantsType>(ConstantsQueryKey);
};
