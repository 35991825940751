import {
  Button,
  Chip,
  Container as MuiContainer,
  Snackbar,
  Stack,
  TextField,
  Typography
} from '@mui/material';
import { useEffect, useState } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { useQuery } from '@tanstack/react-query';
import { useFormik } from 'formik';
//@ts-ignore
import ReCAPTCHA from 'react-google-recaptcha';
import Cookies from 'universal-cookie';

import Container from 'components/common/Container';
import TextInput from 'components/common/TextInput';
import WaveLine from 'components/waveLine/WaveLine';

import custom_palettes from 'theme/custom_palettes';
import api from 'utils/api';
import cms from 'utils/cms';

const EMAIL_API = '/mail/send';

// =============================================================================
export default function ContactUs() {
  const {
    isLoading,
    isError,
    data: query
  } = useQuery({
    queryKey: ['contact-us'],
    queryFn: () =>
      cms.get('/api/contact-us?populate=deep').then((res) => res.data)
  });

  const areasOfEnquiry = ['General Feedback', 'Collaboration', 'Other'];
  const [area, setArea] = useState(0);
  const [isSendLoading, setSendLoading] = useState(false);
  const [captchaDone, setCaptchaDone] = useState(false);
  const [snackbar, setSnackbar] = useState('');

  const formik = useFormik({
    initialValues: {
      name: '',
      email: '',
      phone: '',
      area: '',
      message: ''
    },
    onSubmit: async (values, { resetForm }) => {
      setSendLoading(true);
      const { name, email, phone, area, message } = values;
      // phone can be omitted
      if (!name || !email || !area || !message) {
        setSnackbar('Required fields should not be empty');
        return;
      }

      const res = await api
        .post(
          EMAIL_API,
          {
            userEmail: email,
            subject: `[${area}] Message from ${name} - ${phone}`,
            content: message
          },
          {
            headers: {
              'Content-Type': 'application/json'
            }
          }
        )
        .catch((err) => setSnackbar('Something wrong happened'));
      setSnackbar('Message sent!');
      resetForm();
      setSendLoading(false);
    }
  });

  useEffect(() => {
    formik.setFieldValue('area', areasOfEnquiry[area]);
  }, [area, ...areasOfEnquiry]);

  if (isLoading) return <div>Loading...</div>;
  if (isError) return <div>Something is wrong please reload</div>;

  const data = query?.data.attributes;
  const { description, address } = data;

  return (
    <>
      <Container style={{ marginBottom: 48, marginTop: 48 }}>
        <Typography variant='h2' color='blue.900'>
          Contact Us
        </Typography>
        <div style={{ height: 16 }} />
        <Typography
          variant='body_large'
          color='blue.900'
          style={{ whiteSpace: 'pre-line' }}
        >
          {description}
        </Typography>
      </Container>

      <WaveLine style={{ fill: custom_palettes.green[50] }} />

      <div style={{ background: custom_palettes.green[50] }}>
        <div style={{ height: 80 }} />
        <MuiContainer fixed>
          <Container>
            <Typography variant='h3' color='blue.900'>
              Drop us a message!
            </Typography>
            <form onSubmit={formik.handleSubmit} style={{ margin: '40px 0' }}>
              <TextInput
                variant='outlined'
                id='name'
                name='name'
                label='Name'
                placeholder='Enter your name*'
                value={formik.values.name}
                onChange={formik.handleChange}
                error={formik.touched.name && Boolean(formik.errors.name)}
                helperText={formik.touched.name && formik.errors.name}
                containerStyle={{ width: '100%', maxWidth: 400 }}
              />
              <div style={{ display: 'flex', gap: '5%', flexWrap: 'wrap' }}>
                <TextInput
                  variant='outlined'
                  id='email'
                  name='email'
                  label='Email'
                  placeholder='Enter your email*'
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  helperText={formik.touched.email && formik.errors.email}
                  containerStyle={{ width: '100%', maxWidth: 400 }}
                />
                <TextInput
                  variant='outlined'
                  id='phone'
                  name='phone'
                  label='Phone'
                  placeholder='Enter your phone number'
                  value={formik.values.phone}
                  onChange={formik.handleChange}
                  error={formik.touched.phone && Boolean(formik.errors.phone)}
                  helperText={formik.touched.phone && formik.errors.phone}
                  containerStyle={{ width: '100%', maxWidth: 400 }}
                />
              </div>
              <Typography
                mb={2}
                variant='h6'
                fontWeight={'bold'}
                color='blue.900'
              >
                Area of enquiry
              </Typography>
              <AreaOfEnquiryButtons
                areas={areasOfEnquiry}
                active={area}
                setActive={setArea}
              />
              <Typography
                mb={2}
                variant='h6'
                fontWeight={'bold'}
                color='blue.900'
              >
                Message
              </Typography>
              <TextField
                multiline
                fullWidth
                variant='outlined'
                id='message'
                name='message'
                placeholder='Enter your message*'
                value={formik.values.message}
                onChange={formik.handleChange}
                error={formik.touched.message && Boolean(formik.errors.message)}
                helperText={formik.touched.message && formik.errors.message}
                minRows={5}
                maxRows={5}
                sx={{
                  borderRadius: 3,
                  background: '#fff',
                  '& .MuiOutlinedInput-root': {
                    borderRadius: 3,
                    padding: 0
                  },
                  '& textarea': {
                    padding: '10px 16px'
                  }
                }}
              />
              <div style={{ height: 24 }} />
              <ReCAPTCHA
                sitekey='6LehBksiAAAAANjlY6GgijhF3rCdS0qTrJGUa2yN'
                onChange={() => setCaptchaDone(true)}
              />
              <Button
                type='submit'
                variant='primary'
                color='dark'
                style={{ marginTop: '1.4rem' }}
                disabled={!captchaDone || isSendLoading}
              >
                Send message
              </Button>
              {isSendLoading && (
                <div style={{ margin: 24, marginLeft: 0 }}>
                  <CircularProgress />
                </div>
              )}
            </form>
            <Typography
              align='right'
              variant='body2'
              sx={{
                float: 'right',
                whiteSpace: 'pre-line'
              }}
            >
              {address}
            </Typography>
          </Container>
        </MuiContainer>

        <div style={{ height: 200 }} />
      </div>
      <Snackbar
        open={!!snackbar}
        autoHideDuration={3000}
        message={snackbar}
        onClose={() => setSnackbar('')}
      />
    </>
  );
}

function AreaOfEnquiryButtons(props: any) {
  return (
    <Stack direction='row' spacing={1} mb={3} flexWrap={'wrap'} gap={'10px 0'}>
      {props.areas.map((a: string, idx: number) => {
        if (props.active === idx) {
          return (
            <Chip
              label={a}
              sx={{
                color: '#256F94',
                border: 'none',
                background: '#F7DD97',
                fontWeight: 'bold',
                cursor: 'pointer'
              }}
              key={idx}
            />
          );
        } else {
          return (
            <Chip
              variant='outlined'
              label={a}
              clickable
              onClick={() => props.setActive(idx)}
              sx={{
                color: '#256F94',
                border: '#256F94 solid 2px',
                fontWeight: 'bold'
              }}
              key={idx}
            />
          );
        }
      })}
    </Stack>
  );
}
