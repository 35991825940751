import { useQuery } from '@tanstack/react-query';
import moment from 'moment';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';

import api, { ROUTES } from 'utils/api';

const { getTypedApiData, apiPath: API_PATH } = ROUTES.GET_LATEST_DATA;

export interface BuoyProps {
  title: string;
  description: string;
  buoyCard: any[];
  width: string;
}

type BuoyCard = {
  acronym: string;
  title: string;
  description: string;
};

export const getCardData = (props: BuoyProps) => {
  const buoyCards = props.buoyCard as Array<BuoyCard>;
  return Object.fromEntries(
    buoyCards.map(({ acronym, title, description: content }) => [
      acronym,
      {
        description: {
          title,
          content
        }
      }
    ])
  );
};

type CardData = ReturnType<typeof getCardData>;

// API Response

export const params = [
  ['wind_speed', 'm/s', 1],
  ['water_temperature', 'degree celcius', 2],
  ['air_temperature', 'degC', 2],
  ['air_pressure', 'mbar', 1],
  ['salinity', 'psu', 2],
  ['solar_irradiance', 'W/m2', 1]
];

const defaultResponse = Array(params.length)
  .fill('')
  .map((_v) => 'N/A');

const mapSuccessfulApiResponse = (
  apiResponse: ReturnType<typeof getTypedApiData>
) => {
  return params
    .map(([parameterName, ..._rest]) => apiResponse[parameterName])
    .filter((v) => v !== undefined)
    .map((param) => [param.value, param.unit].join(' '));
};

const mapCardData =
  (activePoint: string, data: ReturnType<typeof mapSuccessfulApiResponse>) =>
  (cardData: CardData) => ({
    ...cardData,
    [activePoint]: {
      ...cardData[activePoint],
      data
    }
  });

export const useUpdateBuoyCard = ({
  activePoint,
  setCardData,
  setOverline
}: {
  activePoint: string;
  setCardData: Dispatch<SetStateAction<CardData>>;
  setOverline: Dispatch<SetStateAction<string>>;
}) => {
  const [refetchInterval, setRefetchInterval] = useState(5 * 60 * 1000);
  const { data, status, error } = useQuery({
    queryKey: ['home', 'buoy', 'card-data', activePoint],
    queryFn: async () => {
      return await api({
        method: 'get',
        url: `${import.meta.env.VITE_API_URL}${API_PATH.replace(/:.+$/, activePoint)}`,
        dontShowGlobalLoader: true
      } as any).then((res) => res.data);
    },
    refetchInterval
  });

  const onError = (error: Error | null) => {
    console.error('An error occurred fetching buoy data:', error);
    setCardData(mapCardData(activePoint, defaultResponse));
    setRefetchInterval(5 * 60 * 1000);
  };

  useEffect(() => {
    if (status === 'success' && data) {
      try {
        const time = moment(Number(data.latestTs))
          .tz('Asia/Singapore')
          .format('hh:mmA, Do MMM');
        setOverline(`Data shown as of ${time}`);
        setCardData(mapCardData(activePoint, mapSuccessfulApiResponse(data)));
        setRefetchInterval(5 * 60 * 1000);
      } catch (error) {
        onError(error as Error);
      }
      return;
    }
    if (status === 'error' && error) {
      onError(error);
    }
  }, [data, status]);
};
