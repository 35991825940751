import ArrowForwardIosNewRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { useQuery } from '@tanstack/react-query';
import { isEmpty } from 'lodash';
import React, { useEffect } from 'react';
import { Else, If, Then } from 'react-if';
import ReactMarkdown from 'react-markdown';
import { Link as RouterLink } from 'react-router-dom';

import Container from 'components/common/Container';
import PageTitle from 'components/text/PageTitle';
import WaveLine from 'components/waveLine/WaveLine';

import custom_palettes from 'theme/custom_palettes';
import custom_typographys from 'theme/custom_typographys';
import cms from 'utils/cms';
import useViewportRatio from 'utils/useViewportRatio';
import useWidth from 'utils/useWidth';

// =============================================================================
export default function ContributeDataPage() {
  const {
    isLoading,
    isError,
    data: query
  } = useQuery({
    queryKey: ['contribute-data'],
    queryFn: () =>
      cms.get('/api/contribute-data?populate=deep').then((res) => res.data)
  });
  const standard = parseInt(import.meta.env.VITE_STANDARD_VIEWPORT as string);
  const width = useWidth();
  const ratio = useViewportRatio(standard);

  if (isLoading) return <div>Loading...</div>;
  if (isError) return <div>Something is wrong please reload</div>;

  const data = query?.data.attributes;

  const { header, image, text, subHeader, hyperLinks } = data;

  return (
    <Container>
      <div className='how-to-parent'>
        {width === 'mobile' ? null : (
          <div className='image-holder not-mobile'>
            <img
              src={import.meta.env.VITE_CMS_URL + image.data.attributes.url}
            />
          </div>
        )}
        <div
          className={`text-holder ${
            width === 'mobile' ? 'mobile' : 'not-mobile'
          }`}
        >
          <div>
            <Container>
              <PageTitle text={header} />
            </Container>
          </div>
          {width === 'mobile' ? (
            <div className='image-holder mobile'>
              <img
                src={import.meta.env.VITE_CMS_URL + image.data.attributes.url}
                width={500 * ratio}
              />
            </div>
          ) : null}
          <div>
            <ReactMarkdown className='markdown'>{text}</ReactMarkdown>
          </div>
          <div
            style={{
              marginLeft: 40
            }}
          >
            <div
              style={{
                marginBottom: 20
              }}
            >
              <Typography variant='subtitle' color='blue.900'>
                {subHeader}
              </Typography>
            </div>
            {hyperLinks.map((el: any, index: any) => (
              <div
                style={{
                  marginBottom: 10,
                  display: 'flex',
                  alignItems: 'center'
                }}
                key={index}
              >
                <ArrowForwardIosNewRoundedIcon
                  sx={{ color: 'red', width: 15, height: 15 }}
                />
                <div style={{ width: 8 }} />
                <Link
                  href={el.link}
                  target='_blank'
                  rel='noopener noreferrer'
                  underline='none'
                  color='blue.800'
                  style={{
                    ...custom_typographys.button,
                    wordBreak: 'break-all'
                  }}
                >
                  {el.text}
                </Link>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div
        style={{
          height: '160px'
        }}
      ></div>
    </Container>
  );
}
