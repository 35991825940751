import { Button } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';

const NotFound: React.FC = () => {
  return (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      minHeight: 400,
      padding: '20px',
      textAlign: 'center',
    }}>
      <h1 style={{ color: '#1e5b80', fontSize: '48px', margin: '0 0 20px 0' }}>404</h1>
      <h2 style={{ color: '#2c3e50', fontSize: '24px', margin: '0 0 20px 0' }}>
        Oops! You've sailed into uncharted waters
      </h2>
      <p style={{ color: '#666', fontSize: '16px', maxWidth: '500px', margin: '0 0 30px 0' }}>
        The buoy you're looking for seems to have drifted away. Let's get you back to familiar waters.
      </p>
      <Button
        variant='primary'
        color='light'
        component={Link}
        to='/'
      >Return to Homepage</Button>
    </div>
  );
};

export default NotFound;
