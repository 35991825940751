import FileSaver from 'file-saver';
import moment from 'moment-timezone';
import { CONSTANTS } from 'constants';

export const exportRawData = (
  fileName: string,
  data: any,
  isCruiseParam: any,
  unit: string
) => {
  let headers, rows;

  if (isCruiseParam) {
    headers = ['Date(SGT)', 'Value', 'QcFlag', 'Unit'];
    rows = [
      headers,
      ...data.map((obj: any) => {
        const sgtTimeStr = moment(Number(obj.dataTs))
          .tz('Asia/Singapore')
          .format('YYYY-MM-DDTHH:mm:ss.SSSZ');
        return [sgtTimeStr, obj.qcValue, obj.qcFlag, unit];
      })
    ];
  } else if (fileName.includes('monthly_avg')) {
    headers = ['Date(SGT)', 'Value', 'QcFlag', 'Unit'];
    rows = [
      headers,
      ...data.map((obj: any) => [
        moment
          .utc(Number(obj.timestamp))
          .tz('Asia/Singapore')
          .format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
        obj.qcValue,
        obj.qcFlag,
        unit
      ])
    ];
  } else {
    headers = ['ID', 'Date(SGT)', 'Value', 'QcFlag', 'Unit'];
    rows = [
      headers,
      ...data.map((obj: any) => [
        obj.dataId,
        moment
          .utc(Number(obj.timestamp))
          .tz('Asia/Singapore')
          .format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
        obj.qcValue,
        obj.qcFlag,
        unit
      ])
    ];
  }

  let csvContent = new File(
    [rows.map((e) => e.join(',')).join('\r\n')],
    fileName + '.csv',
    {
      type: 'text/csv;charset=utf-8'
    }
  );
  FileSaver.saveAs(csvContent);
};
