import { useQuery } from '@tanstack/react-query';
import axios from 'axios';

const cms = axios.create({
  baseURL: import.meta.env.VITE_CMS_URL,
  headers: {
    'Content-Type': 'application/json'
  }
});

export default function useCms(key: string, route: string, params?: string[]) {
  const paramString = formatParameters(params);

  const {
    isLoading,
    isError,
    data: query
  } = useQuery({
    queryKey: [key],
    queryFn: () =>
      cms
        .get('/api/' + route + '?populate=deep' + paramString)
        .then((res) => res.data)
  });

  return {
    isLoading,
    isError,
    query
  };
}

function formatParameters(params: string[] | undefined) {
  let res: string = '';
  params?.forEach((param) => (res += '&' + param));
  return res;
}

export const RESPONSES = {
  PARAMETER: {
    LOCATIONS: {
      SJI: 'SJI',
      RLH: 'RLH',
      UBN: 'UBN'
    }
  }
};
