import { useQuery } from '@tanstack/react-query';

import WaveLine from 'components/waveLine/WaveLine';

import custom_palettes from 'theme/custom_palettes';
import cms from 'utils/cms';
import useWidth from 'utils/useWidth';

import Featured from '../Highlights/Featured';
import Account from './Account';
import Buoy from './Buoy/Index';
import Collab from './Collab';
import Graph from './Graph/Index';
import Main from './Main/Index';

// =============================================================================
export default function Home() {
  const width = useWidth();
  const {
    isLoading,
    isError,
    data: query
  } = useQuery({
    queryKey: ['homepage'],
    queryFn: () =>
      cms.get('/api/homepage?populate=deep').then((res) => res.data)
  });

  if (isLoading) return <div>Loading...</div>;
  if (isError) return <div>Something is wrong please reload</div>;

  const data = query?.data.attributes;

  const {
    main,
    buoy,
    buoyCard,
    graph,
    icon,
    accountTitle,
    collaborationTitle,
    logo,
    announcement
  } = data;

  return (
    <div>
      <Main {...main} announcement={announcement} width={width} />

      <Buoy {...buoy} buoyCard={buoyCard} width={width} />

      <Graph {...graph} width={width} />

      <WaveLine style={{ fill: custom_palettes.blue[50] }} />
      <Featured />

      <div style={{ marginTop: 150 }}>
        <Account accountTitle={accountTitle} icon={icon} width={width} />
      </div>

      <div style={{ marginTop: 96 }}>
        <Collab
          collaborationTitle={collaborationTitle}
          logo={logo}
          width={width}
        />
      </div>
      <div style={{ height: 160 }} />
    </div>
  );
}
