import AccordionDetails from '@mui/material/AccordionDetails';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import React from 'react';
import { v4 as uuid } from 'uuid';

import HeadingBody from 'components/text/HeadingBody';
import custom_palettes from 'theme/custom_palettes';

import { ReactComponent as ClimateChangeV2Icon } from 'static/icons/ClimateChangeV2.svg';
import { ReactComponent as EnvironmentImpactAssessmentsV2Icon } from 'static/icons/EnvironmentImpactAssessmentsV2.svg';
import { ReactComponent as MarineEcologyV2Icon } from 'static/icons/MarineEcologyV2.svg';
import { ReactComponent as MarineSensingTechnologies } from 'static/icons/MarineSensingTechnologies.svg';
import { ReactComponent as OceanBiogeochemistryV2Icon } from 'static/icons/OceanBiogeochemistryV2.svg';
import { ReactComponent as PhysicalOceanographyIcon } from 'static/icons/PhysicalOceanography.svg';
import useWidth from 'utils/useWidth';

import { parameterLocations } from './utils';

// =============================================================================
const ICON_OPACITY = 0.2;

const icon_size = {
  height: 32,
  width: 32
};

const locationStyle = {
  ...icon_size,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '0 10px',
  color: '#00B087',
  border: '2px solid #00B087',
  borderRadius: '15px'
};

// =============================================================================
interface DetailsProps {
  importance: string;
  dataAccuracy: string;
  acquiredBy: string;
  locations: any;
}

export default function Details(props: DetailsProps) {
  const width = useWidth();
  const { importance, dataAccuracy, locations } = props;

  return (
    <AccordionDetails
      style={{
        backgroundColor: custom_palettes.yellow[50]
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={width === 'mobile' ? 12 : 6}>
          <div>
            <Typography variant='body_large_strong' color='blue.900'>
              Importance
            </Typography>
            <br />
            <br />
            <Typography variant='body_regular' color='blue.900'>
              {importance}
            </Typography>
          </div>
          <div style={{ height: 24 }} />
        </Grid>

        <Grid item xs={width === 'mobile' ? 12 : 6}>
          <div>
            <Typography variant='body_large_strong' color='blue.900'>
              Data Accuracy
            </Typography>
            <br />
            <br />
            <Typography variant='body_regular' color='blue.900'>
              {dataAccuracy}
            </Typography>
          </div>
        </Grid>

        {width === 'mobile' ? (
          <Grid item xs={width === 'mobile' ? 12 : 6}>
            <div>
              <Typography variant='body_large_strong' color='blue.900'>
                Available at
              </Typography>
              <br />
              <br />
              <div
                style={{
                  display: 'flex',
                  gridGap: '20px'
                }}
              >
                {Object.keys(locations).map((location) => (
                  <div
                    key={location}
                    style={{
                      opacity: locations[location] ? 1 : ICON_OPACITY,
                      ...locationStyle
                    }}
                  >
                    {parameterLocations[location] ?? location}
                  </div>
                ))}
              </div>
            </div>
          </Grid>
        ) : null}
      </Grid>
    </AccordionDetails>
  );
}
